import React, { useState, createContext } from 'react';

// create a order context

const OrderContext = createContext();

export function OrderProvider({ children }) {
  // wwe need to stick state in here
  const [orders, setOrder] = useState([]);
  return (
    <OrderContext.Provider value={[orders, setOrder]}>
      {children}
    </OrderContext.Provider>
  );
}

export default OrderContext;
