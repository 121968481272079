import React, { useRef } from 'react';
import Footer from './Footer.component';
import Nav from './Nav.component';
import MessengerChat from './MessengerChat.component';
import '../styles/global.css';

export default function Layout({ children }) {
  const refInput = useRef('fb-msgr');
  return (
    <>
      <MessengerChat
        pageId={`${process.env.GATSBY_FB_PAGE_ID}`}
        ref={refInput}
        themeColor="#FF4949"
        loggedInGreeting="Xin chào, Cà phê Nhã Thy có thể giúp gì cho bạn?"
        loggedOutGreeting="Xin chào, Cà phê Nhã Thy có thể giúp gì cho bạn?"
      />
      {/* <GlobalStyles /> */}
      {/* <Typography /> */}
      <Nav />
      {children}

      <Footer />
    </>
  );
}
