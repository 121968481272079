import { Link } from 'gatsby';
import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

const FooterStyles = styled.footer`
  ${tw`py-8 text-gray-600 border-t`};
  .footer {
    &__up {
      ${tw`container flex flex-col items-center px-5 py-3 mx-auto sm:flex-row`};
      &--address {
        ${tw`mt-4 text-sm text-center text-gray-500 sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-200 sm:py-2 sm:mt-0 md:text-left`}
      }
      &--logo {
        ${tw`flex flex-wrap items-center justify-center font-medium text-gray-900 md:justify-start md:flex-nowrap`}
        .gatsby-image-wrapper {
          ${tw`max-w-[150px] h-auto p-2`}
          img {
            object-fit: contain !important;
          }
        }

        span {
          ${tw`block w-full mt-3 text-xl text-center md:ml-3 md:mt-0 md:inline md:text-left`}
        }
      }

      &--social {
        ${tw`inline-flex justify-center mt-4 sm:ml-auto sm:mt-0 sm:justify-start`}
        a {
          ${tw`ml-3 text-gray-500`}
          &:first-child {
            ${tw`ml-0 text-gray-500`}
          }
          svg {
            ${tw`w-5 h-5`}
          }
        }
      }
    }
    &__bot {
      ${tw`container px-5 py-3 mx-auto`}
      p {
        ${tw`mt-4 text-sm text-center text-gray-500 md:text-right`}
      }
      a {
        ${tw`text-gray-600`}
      }
    }
  }
`;

export default function Footer() {
  return (
    <FooterStyles className="footer">
      <div className="footer__up">
        <Link to="/" className="footer__up--logo">
          <StaticImage src="../images/logo.jpg" alt="logo" />
          <span>CÔNG TY NHÃ THY</span>
        </Link>
        <p className="footer__up--address">
          241 Nguyễn Văn Tiết. P.Phú Cường
          <br />
          TP. TDM, Bình Dương <br />
          ©2021
        </p>
        <span className="footer__up--social">
          <Link
            to="https://facebook.com"
            target="_blank"
            className="text-gray-500"
          >
            <svg
              fill="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
            >
              <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z" />
            </svg>
          </Link>
          <Link to="https://twitter.com">
            <svg
              fill="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
            >
              <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z" />
            </svg>
          </Link>
          <Link to="https://linkedin.com" target="_blank">
            <svg
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
            >
              <rect width="20" height="20" x="2" y="2" rx="5" ry="5" />
              <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01" />
            </svg>
          </Link>
          <Link to="https://instagram.com" target="_blank">
            <svg
              fill="currentColor"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0"
              viewBox="0 0 24 24"
            >
              <path
                stroke="none"
                d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"
              />
              <circle cx="4" cy="4" r="2" stroke="none" />
            </svg>
          </Link>
        </span>
      </div>
      <div className="footer__bot">
        <p>
          <Link
            to="https://tada247.xyz"
            rel="noopener noreferrer"
            target="_blank"
          >
            Designed by tada247.xyz
          </Link>
        </p>
      </div>
    </FooterStyles>
  );
}
