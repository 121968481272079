import React from 'react';
import Layout from './src/components/Layout.component';
import { OrderProvider } from './src/components/Order/OrderContext.component';
require('typeface-roboto');
require('typeface-montserrat');

export function wrapPageElement({ element, props }) {
  return <Layout {...props}>{element}</Layout>;
}

export function wrapRootElement({ element }) {
  return <OrderProvider>{element}</OrderProvider>;
}
