import React from 'react';
import { Link } from 'gatsby';
import tw from 'twin.macro';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

const NavStyles = styled.header`
  ${tw`text-gray-600 shadow`};
  > div {
    ${tw`container flex flex-col flex-wrap items-center px-5 py-2 mx-auto md:flex-row`}
  }
  .logo {
    ${tw`flex items-center mb-4 font-medium text-gray-900 text-2_5xl md:mb-0 max-w-[200px]`}
    img {
      object-fit: contain !important;
    }
  }
  svg {
    ${tw`w-10 h-10 p-2 text-white bg-indigo-500 rounded-full`}
  }
  nav {
    ${tw`flex flex-wrap items-center justify-center text-lg md:ml-auto`}
    a {
      ${tw`mr-5 border-b border-transparent hover:text-primary `}
      &[aria-current="page"] {
        ${tw`text-primary border-primary`}
      }
    }
  }
`;

const navs = [
  {
    name: 'Sản phẩm',
    link: '/products',
  },
  {
    name: 'Về chúng tôi',
    link: '/about',
  },
  {
    name: 'Chứng nhận',
    link: '/awards',
  },
  {
    name: 'Đặt hàng',
    link: '/orders',
  },
];

export default function Nav() {
  return (
    <NavStyles>
      <div>
        <Link className="logo" to="/">
          <StaticImage src="../images/logo.jpg" alt="logo" />
        </Link>
        <nav>
          {navs.map((nav, idx) => (
            <Link key={`${nav.name}-${idx}`} to={nav.link}>
              {nav.name}
            </Link>
          ))}
        </nav>
      </div>
    </NavStyles>
  );
}
